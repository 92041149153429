<template> 
<div class="row" v-if="filterTemplates">

    <div class="col-3 pe-1" v-if=" filterTemplates.fieldsDS">
          <ODataLookup :data-object="filterTemplates.fieldsDS"
            :placeholder="$t('Field')"
            :bind="sel=> { filterTemplates.newItem.column = sel.FieldName;filterTemplates.newItem.valueType=convertType(sel.DataType);filterTemplates.newItem.caption=sel.DisplayName;filterTemplates.newItem.operator=getDefaultOperator(filterTemplates.newItem.valueType);}"
            class="form-select form-select-sm rounded-0"
            disableDynamicLoading
            v-model="filterTemplates.newItem.caption">
         
            <OColumn name="DisplayName" width="200"/> 
            <OColumn name="DataType" width="100"/>
        </ODataLookup>
    </div>
    <div class="col-2 px-1">
        <select v-model="filterTemplates.newItem.operator" class="form-select form-select-sm rounded-0" :disabled="disableOperatorSelect">
            <option value="" disabled selected hidden>{{$t('Operator')}}</option>
            <option v-if="filterTemplates.newItem.valueType" v-for="op in getOperatorsList(filterTemplates.newItem.valueType).filter((op)=> !op.title.toLowerCase().includes('between'))" :value = "op.name">{{op.title}}</option>
        </select> 
    </div>
    <div class="col-2 px-1">
        <select v-model="filterTemplates.newItem.inputEditor" class="form-select form-select-sm rounded-0" @change="() => {filterTemplates.updateNewItem(); clearEditorValue()}" :disabled="disableInputEditorSelect">
            <option value="Input Editor" selected v-if="!disableInputEditor" :disabled="disableInputEditor">{{$t('Input Editor')}}</option>
            <option value="Field" v-if="!disableInputEditor" :disabled="disableInputEditor">{{$t('Field')}}</option>
            <option value="Fixed Value">{{$t('Fixed Value')}}</option>
        </select> 
    </div>
    <div class="col-4 px-1">
        <ODataLookup :data-object="filterTemplates.fieldsDS" v-if="filterTemplates.newItem.inputEditor === 'Field'"
                :placeholder="$t('Field')"
                :bind="sel=> { filterTemplates.newItem.value = '['+sel.FieldName+']';filterTemplates.newItem.displayValue = '['+sel.DisplayName+']';}"
                class="form-select form-select-sm rounded-0"
                v-model="filterTemplates.newItem.displayValue">
            <OColumn name="DisplayName" width="200"/> 
            <OColumn name="DataType" width="100"/>
        </ODataLookup>
        <ODataLookup :data-object="dsInputEditors" v-if="filterTemplates.newItem.inputEditor === 'Input Editor'" :disabled="!filterTemplates.newItem.operator"
                :placeholder="$t('Input Editor')"
                :bind="sel=> { filterTemplates.newItem.value = sel.Name;filterTemplates.newItem.editorType = sel.ConfigJson.Type}"
                class="form-select form-select-sm rounded-0"
                v-model="filterTemplates.newItem.value">
            <OColumn name="Name" width="250"/> 
        
        </ODataLookup>
        
        <FieldFilter :filterObject="filterTemplates.filterObject" hideOperators hideColumn :hideDropdown="disableDropDown" :columnName="filterTemplates.newItem.column" :key="filterTemplates.newItem.column" v-if="showFieldFilter"/>
    </div>
      <div class="col-1">
        <button class="btn btn-sm btn-outline-primary ms-auto rounded-0" :disabled="disableAdd" @click="()=>filterTemplates.add()">{{$t('Add')}}</button>
    </div>
</div>
</template>

<script setup>
    import {getOperatorsList,getDefaultOperator} from 'o365.modules.filterUtils.ts';
    import {convertType} from 'o365.modules.utils.fields.ts';
    import {dsInputEditors} from 'o365.modules.inputEditorsConfigs.ts';
    import FieldFilter from 'o365.vue.components.FieldFilter.vue';
    import {computed} from 'vue';

    const props = defineProps({
        filterTemplates:Object
    });

    const disableInputEditor = computed(()=>{
        let disabled = props.filterTemplates.newItem.operator?.includes('between') || props.filterTemplates.newItem.operator?.includes('notbetween');
        return disabled;
    });

    const disableDropDown = computed(()=>{
        let disabled = props.filterTemplates.newItem.operator?.includes('dateequals') 
        || props.filterTemplates.newItem.operator?.includes('dategreaterthan')
        || props.filterTemplates.newItem.operator?.includes('dategreaterthanorequal')
        || props.filterTemplates.newItem.operator?.includes('datelessthan')
        || props.filterTemplates.newItem.operator?.includes('datelessthanorequal')
        || props.filterTemplates.newItem.operator?.includes('isnull')
        || props.filterTemplates.newItem.operator?.includes('isnotnull');
        return disabled;
    });


    const disableAdd = computed(()=>{

        let disabled = false;
        const operator = props.filterTemplates.newItem.operator;

        if ( !['isblank', 'isnotblank', 'isnull','isnotnull', 'istrue', 'isfalse'].includes(operator)) {
            disabled = !props.filterTemplates.newItem.inputEditor || !props.filterTemplates.newItem.operator
        }

        return disabled;
    })

    const disableOperatorSelect = computed(()=>{
        let disabled = false;
        disabled = !props.filterTemplates.newItem.valueType;
        return disabled;
    })

    const disableInputEditorSelect = computed(()=>{
        let disabled = false;
        const operator = props.filterTemplates.newItem.operator;

        disabled = !props.filterTemplates.newItem.operator || ['isblank', 'isnotblank', 'isnull','isnotnull', 'istrue', 'isfalse'].includes(operator);
        return disabled;
    })

    const showFieldFilter = computed(()=>{
        return props.filterTemplates.newItem.inputEditor === 'Fixed Value' && props.filterTemplates.newItem.column && !disableInputEditorSelect.value;
    })
    const clearEditorValue = () =>{
        props.filterTemplates.newItem.value = ''
        props.filterTemplates.newItem.displayValue = ''
    }


</script>